import { useEffect, useState } from "react";
import { useAppState } from "../../../state";

const PARAMS = {
  'itab': '',
  // TODO: Add support to pre-select distro and version
  // 'distro': '',
  'htab': '',
  'os': '',
  'config': '',
};

/**
 * Component provides hidden input field whose value updates on configuration change.
 * The value can be inspected and copied for use in creating pre-configured links to
 * the configurator.
 */
export default function ProductLinkReference() {
  const [{ configuration: configState }] = useAppState();
  const [configLink, setConfigLink] = useState('');

  useEffect(() => {
    const os = configState.operatingSystemType;
    const itab = configState.operatingSystemTab;
    const htab = configState.hardwareTab;
    const config = configState.hardwareOption;

    const { origin, pathname } = window.location;
    const searchParams = new URLSearchParams(Object.assign(PARAMS, {
      itab,
      htab,
      os,
      config,
    }));

    setConfigLink(() => `${origin}${pathname}?${searchParams.toString()}`);
  }, [configState]);

  return <input id="lw-product-link" type="hidden" value={configLink} />;
}

