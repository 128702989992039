/**
 * Scroll to an element relative to the document body
 * @param element Element to scroll to
 * @param offset Scroll offset value
 * @returns void
 */
export default function scrollToElement(element, offset = 0) {
  if (!element) {
    return;
  }

  window.scrollTo({
    behavior: "smooth",
    top:
      element.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top -
      offset,
  });
}
