import { forwardRef } from "react";

import { useAppState } from "../../../../state";
import { removeDecimalIfWhole } from "../../../../utils/removeDecimalIfWhole";
import { SkeletonCard } from "../../common/skeleton-card";

export const OperatingSystemSection = forwardRef(function (props, ref) {
  const [{ configuration: configState, summary: summaryState }] = useAppState();
  const { distro, management, controlPanel, controlPanelLicense } =
    summaryState;
  const { onClick } = props;

  return (
    <table className="text-sm w-full" aria-label="Operating System Choices">
      <caption>
        <div className="flex justify-between w-full text-xs mb-2">
          <span className="uppercase text-lw-text-disabled tracking-wide">
            OS
          </span>
          <button
            aria-label="Edit Operating System configuration choices"
            className="text-xs underline text-lw-text-disabled hover:text-lw-text-primary transition-all"
            onClick={() => onClick(ref)}
          >
            Edit
          </button>
        </div>
      </caption>
      <thead className="sr-only">
        <tr>
          <th>Operating System</th>
          <th>Cost</th>
        </tr>
      </thead>
      <tbody>
        {configState.isLoading || configState.isError === "api-fetch" ? (
          Array(5)
            .fill()
            .map((_, index) => (
              <tr key={index}>
                <td>
                  <SkeletonCard className="w-full h-3 my-[3px] !rounded-sm !bg-lw-ui-border" />
                </td>
              </tr>
            ))
        ) : (
          <>
            <tr>
              <td>Template</td>
              <td></td>
            </tr>
            <tr className="align-top">
              <td>
                <span className="text-lw-text-disabled">{`${distro.title}: `}</span>
                {distro.value}
              </td>
              <td className="text-right"></td>
            </tr>
            <tr className="align-top">
              <td>
                <span className="text-lw-text-disabled">{`${management.title}: `}</span>
                {management.value}
              </td>
              <td className="text-right">
                {management?.cost ? `$${removeDecimalIfWhole(management.cost)}` : ''}
              </td>
            </tr>
            <tr className="align-top">
              <td>
                <span className="text-lw-text-disabled">{`${controlPanel.title}: `}</span>
                {controlPanel.value}
              </td>
              <td className="text-right">
                {controlPanel?.cost ? `$${removeDecimalIfWhole(controlPanel.cost)}` : ''}
              </td>
            </tr>
            {controlPanelLicense?.value ? (
              <tr className="align-top">
                <td>
                  <span className="text-lw-text-disabled">{`${controlPanelLicense.title}: `}</span>
                  {controlPanelLicense.value}
                </td>
                <td className="text-right">
                  {controlPanelLicense?.cost ? `$${removeDecimalIfWhole(controlPanelLicense.cost)}` : ''}
                </td>
              </tr>
            ) : null}
          </>
        )}
      </tbody>
    </table>
  );
});
