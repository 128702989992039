import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { CardButtonBase } from "../card/CardButtonBase";

export const SelectableCard = forwardRef(function SelectableCard(
  {
    className,
    startElement,
    endElement,
    endDivider,
    title,
    subheader,
    disabled,
    isSelected,
    onClick,
  },
  ref,
) {
  const startClasses = classnames("mr-2", {
    grayscale: disabled,
    "opacity-60": disabled,
  });
  const endClasses = classnames({
    "sm:border-l": endDivider,
    "sm:border-l-lw-ui-border": endDivider,
    "sm:pl-6": endDivider,
  });

  return (
    <CardButtonBase
      ref={ref}
      onClick={onClick}
      isSelected={isSelected}
      disabled={disabled}
      className={className}
    >
      {startElement ? <div className={startClasses}>{startElement}</div> : null}
      {title || subheader ? (
        <div className="grow">
          {title ? title : null}
          {subheader ? subheader : null}
        </div>
      ) : null}
      {endElement ? <div className={endClasses}>{endElement}</div> : null}
    </CardButtonBase>
  );
});

SelectableCard.propTypes = {
  className: PropTypes.string,
  startElement: PropTypes.any,
  endElement: PropTypes.node,
  endDivider: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
