import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import Chat from "./components/chat";
import {
  SERVER_TYPE_CLOUD_METAL,
  SERVER_TYPE_CLOUD_VPS,
  SERVER_TYPE_BARE_METAL,
  SERVER_TYPE_GPU,
} from "./components/configuration/constants";
import Configuration from "./pages/Configuration";
import { AppStateProvider } from "./state";

const queryClient = new QueryClient();

// Handle incoming wildcard and legacy links
// containing the "type" query param
const RedirectCloudStudio = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const serverTypePath =
      searchParams.get("type") === "metal"
        ? SERVER_TYPE_CLOUD_METAL
        : SERVER_TYPE_CLOUD_VPS;

    // Remove "type" from query string since the route represents this now
    searchParams.delete("type");

    return navigate({
      pathname: `/${serverTypePath}/`,
      search: `?${searchParams.toString()}`,
    });
  }, [searchParams, navigate]);
};

export function App() {
  return (
    <BrowserRouter basename="/configuration">
      <AppStateProvider>
        <QueryClientProvider client={queryClient}>
          <Chat />
          <Routes>
            <Route path={SERVER_TYPE_CLOUD_VPS} element={<Configuration />} />
            <Route path={SERVER_TYPE_CLOUD_METAL} element={<Configuration />} />
            <Route path={SERVER_TYPE_BARE_METAL} element={<Configuration />} />
            <Route path={SERVER_TYPE_GPU} element={<Configuration />} />
            <Route path="*" element={<RedirectCloudStudio />} />
          </Routes>
        </QueryClientProvider>
      </AppStateProvider>
    </BrowserRouter>
  );
}
