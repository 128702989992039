import production from './production';
import staging from './staging';
import development from './development';

const env = process.env.NODE_ENV || 'development';
let config = development;

if (env === 'production') {
  config = production;
} else if (env === 'staging') {
  config = staging;
}

export default config;
