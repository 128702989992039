import BareMetalLogo from "jsx:../../../images/bare-metal.inline.svg";
import CloudMetalLogo from "jsx:../../../images/cloud-metal.inline.svg";
import CloudVpsLogo from "jsx:../../../images/cloud-vps.inline.svg";
import GPULogo from "jsx:../../../images/gpu.inline.svg";

export const PRODUCT_VPS_LIN = "Cloud.VPS";
export const PRODUCT_VPS_WIN = "Cloud.VPS.WIN";
export const PRODUCT_METAL_LIN = "Cloud.Metal";
export const PRODUCT_METAL_WIN = "Cloud.Metal.WIN";
export const PRODUCT_BARE_METAL = "PKG-Bare.Metal";
export const PRODUCT_GPU = "PKG-Bare.Metal.GPU";

export const CLOUD_PRODUCTS = [
  PRODUCT_VPS_LIN,
  PRODUCT_VPS_WIN,
  PRODUCT_METAL_LIN,
  PRODUCT_METAL_WIN,
  PRODUCT_BARE_METAL,
  PRODUCT_GPU,
];

export const SERVER_TYPE_CLOUD_VPS = "cloud-vps";
export const SERVER_TYPE_CLOUD_METAL = "bare-metal-cloud";
export const SERVER_TYPE_BARE_METAL = "bare-metal-server";
export const SERVER_TYPE_GPU = "gpu-accelerated";

export const PRODUCT_ROUTE_MAP = new Map([
  [SERVER_TYPE_CLOUD_VPS, PRODUCT_VPS_LIN],
  [SERVER_TYPE_CLOUD_METAL, PRODUCT_METAL_LIN],
  [SERVER_TYPE_BARE_METAL, PRODUCT_BARE_METAL],
  [SERVER_TYPE_GPU, PRODUCT_GPU],
]);

export const PRODUCT_TYPES = {
  [SERVER_TYPE_CLOUD_VPS]: {
    id: "vps",
    productCode: PRODUCT_VPS_LIN,
    name: "Cloud VPS",
    description: "Single-tenant cloud environments offer the perfect blend of control and affordability.",
    cost: "From $5 Monthly",
    logo: <CloudVpsLogo />,
    path: `/${SERVER_TYPE_CLOUD_VPS}/`,
  },
  [SERVER_TYPE_CLOUD_METAL]: {
    id: "cloud-metal",
    productCode: PRODUCT_METAL_LIN,
    name: "Bare Metal Cloud",
    description: "High-performance dedicated single-tenant servers plus advanced virtualization equals fully customizable hosting.",
    cost: "From $89 Monthly",
    logo: <CloudMetalLogo />,
    path: `/${SERVER_TYPE_CLOUD_METAL}/`,
  },
  [SERVER_TYPE_BARE_METAL]: {
    id: "bare-metal",
    productCode: PRODUCT_BARE_METAL,
    name: "Bare Metal Server",
    description: "Our metal solutions deliver peak performance, precise control, dedicated resources, and high reliability.",
    cost: "From $79 Monthly",
    logo: <BareMetalLogo />,
    path: `/${SERVER_TYPE_BARE_METAL}/`,
  },
  [SERVER_TYPE_GPU]: {
    id: "gpu",
    productCode: PRODUCT_GPU,
    name: "GPU Accelerated",
    description: "High-performance servers with dedicated GPUs for accelerated workloads like AI, machine learning, and graphics rendering.",
    cost: "From $880 Monthly",
    logo: <GPULogo />,
    path: `/${SERVER_TYPE_GPU}/`,
  },
};

export const CYCLE_MAP = new Map([
  ["monthly", "Monthly"],
  ["yearly", "1 Year"],
  ["biennially", "2 Years"],
]);

export const OS_TABS = [
  {
    id: 'os',
    name: 'Operating Systems',
  },
  {
    id: 'app',
    name: 'Applications',
  }
];

export const CART_COOKIE_NAME = "lwCartID";
export const CART_DETAILS_URL =
  "https://api.liquidweb.com/market/cart/helpers/configurator/details";
export const ADD_PRODUCT_TO_CART_URL =
  "https://api.liquidweb.com/cloud/server/addToCart";
export const ADD_PACKAGE_TO_CART_URL =
  "https://api.liquidweb.com/market/cart/helpers/configurator/add";

export const BASIC_AUTH = {
  username: "remote-signup",
  password: "s|gnm3up",
};

export const ROOT_MARGIN = "-25% 0% -75% 0%";
export const SCROLL_OFFSET = 180;
export const CONFIGURATION_SECTIONS = [
  {
    id: "server-type",
    label: "Server Type",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "os",
    label: "Operating System",
  },
  {
    id: "management",
    label: "Management",
  },
  {
    id: "image",
    label: "Image",
  },
  {
    id: "hardware",
    label: "Hardware",
  },
  {
    id: "add-ons",
    label: "Add-Ons",
  },
];

export const CONFIGURATION_SECTIONS_BARE_METAL = [
  {
    id: "server-type",
    label: "Server Type",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "image",
    label: "Image",
  },
  {
    id: "hardware",
    label: "Hardware",
  },
  {
    id: "subscription",
    label: "Subscription",
  },
  {
    id: "add-ons",
    label: "Add-Ons",
  },
];

export const VPS_MAP = new Map([
  ["cores", { label: "Cores", suffix: "" }],
  ["memory", { label: "Memory", suffix: "GB" }],
  ["disk", { label: "Disk", suffix: "" }],
  ["bandwidth", { label: "Bandwidth", suffix: "" }],
]);

export const BARE_METAL_MAP = new Map([
  ["cores", { label: "Cores / Threads", suffix: "" }],
  ["cpu_speed", { label: "Speed", suffix: "" }],
  ["memory", { label: "Memory", suffix: "GB" }],
  ["disk", { label: "Disk", suffix: "" }],
]);

export const METAL_MAP = new Map([
  ["cores", { label: "Cores", suffix: "" }],
  ["cpu_speed", { label: "Speed", suffix: "" }],
  ["memory", { label: "Memory", suffix: "GB" }],
  ["disk", { label: "Disk", suffix: "" }],
  ["bandwidth", { label: "Bandwidth", suffix: "" }],
]);

export const SUMMARY_HARDWARE_VPS = new Map([
  ["cores", { label: "Cores", suffix: "" }],
  ["memory", { label: "Memory", suffix: "GB" }],
  ["disk", { label: "Disk", suffix: "" }],
  ["bandwidth", { label: "Bandwidth", suffix: "" }],
]);

export const SUMMARY_HARDWARE_VPS_WIN = new Map([
  ["cores", { label: "Cores", suffix: "" }],
  ["memory", { label: "Memory", suffix: "GB" }],
  ["disk", { label: "Disk", suffix: "" }],
  ["bandwidth", { label: "Bandwidth", suffix: "" }],
]);

export const SUMMARY_HARDWARE_METAL = new Map([
  ["cores", { label: "Cores", suffix: "" }],
  ["cpu_speed", { label: "Speed", suffix: "" }],
  ["memory", { label: "Memory", suffix: "GB" }],
  ["disk", { label: "Disk", suffix: "" }],
  ["bandwidth", { label: "Bandwidth", suffix: "" }],
]);

export const SUMMARY_HARDWARE_BARE_METAL = new Map([
  ["cores", { label: "Cores / Threads", suffix: "" }],
  ["cpu_speed", { label: "Speed", suffix: "" }],
  ["memory", { label: "Memory", suffix: "GB" }],
  ["disk", { label: "Disk", suffix: "" }],
  ["bandwidth", { label: "Bandwidth", suffix: "" }],
]);

export const SUMMARY_HARDWARE_METAL_WIN = new Map([
  ["cores", { label: "Cores", suffix: "" }],
  ["cpu_speed", { label: "Speed", suffix: "MHz" }],
  ["memory", { label: "Memory", suffix: "GB" }],
  ["disk", { label: "Disk", suffix: "GB" }],
  ["bandwidth", { label: "Bandwidth", suffix: "" }],
]);

export const SUMMARY_ROUTE_MAP = new Map([
  [SERVER_TYPE_CLOUD_VPS, SUMMARY_HARDWARE_VPS],
  [SERVER_TYPE_CLOUD_METAL, SUMMARY_HARDWARE_METAL],
  [SERVER_TYPE_BARE_METAL, SUMMARY_HARDWARE_BARE_METAL],
  [SERVER_TYPE_GPU, SUMMARY_HARDWARE_BARE_METAL],
]);

export const SUMMARY_ADDONS = [
  "backups",
  "blockStorage",
  "ipAddresses",
  "ipv6",
  "ddos",
];

export const SUMMARY_ADDONS_BARE_METAL = ["ddos"];

export const SUMMARY_ADDONS_WIN = ["antiVirus", "mssql"];

export const LOCATION_MAP = new Map([
  [
    "lan",
    {
      city: "Michigan",
      region: "US-Central",
      country: "United States",
      countryCode: "usa",
    },
  ],
  [
    "phx",
    {
      city: "Arizona",
      region: "US-West",
      country: "United States",
      countryCode: "usa",
    },
  ],
  [
    "ash",
    {
      city: "Virginia",
      region: "US-East",
      country: "United States",
      countryCode: "usa",
    },
  ],
  [
    "sjc",
    {
      city: "California",
      region: "US-West",
      country: "United States",
      countryCode: "usa",
    },
  ],
  [
    "lon",
    {
      city: "London",
      region: "EU-West",
      country: "United Kingdom",
      countryCode: "uk",
    },
  ],
  [
    "syd",
    {
      city: "Sydney",
      region: "AP-Southeast",
      country: "Australia",
      countryCode: "aus",
    },
  ],
  [
    "ams",
    {
      city: "Amsterdam",
      region: "EU-West",
      country: "Netherlands",
      countryCode: "nl",
    },
  ],
]);

export const HARDWARE_CATEGORY_ORDER = ["gp", "mem-opt", "cpu-opt"];

export const ERROR_MANAGEMENT_LEVEL =
  "Please choose another location to see more options.";
export const ERROR_API_FETCH =
  "There was a problem loading our product data. Please refresh and try again";
export const ERROR_API_POST =
  "There was a problem adding deploying your configuration. Please contact our support.";

export const COMPARE_MANAGEMENT_LEVELS = [
  ["24/7/365", 1, 1, 1],
  ["Fully Managed Network Infrastructure", 1, 1, 1],
  ["Fully Managed Hardware", 1, 1, 1],
  ["Wholly owned Liquid Web Data Centers", 1, 1, 1],
  ["Level 3 Technicians On-site 24/7/365", 1, 1, 1],
  ["System Level Health Monitoring and Graphing", 1, 1, 1],
  ["System Level Monitoring Alerts & Notifications", 1, 1, 1],
  ["100% Uptime SLA on These Items", 1, 1, 1],
  ["Dedicated Account Executive Team", 1, 1, 1],
  ["Installation and Full Support of Core Software Package", 0, 1, 1],
  ["Core Operating System Updates & Patches", 0, 1, 1],
  ["Security Enhancements", 0, 1, 1],
  ["Full Web Server Support including Apache (Linux)", 0, 1, 1],
  ["Proactive Response & Restoration of Monitoring Events", 0, 1, 1],
  ["Virus and Spam Protection", 0, 0, 1],
  ["Free External Migrations**", 0, 0, 1],
  ["Full Control Panel Support", 0, 0, 1],
  ["Control Panel Updates and Patches", 0, 0, 1],
];

export const HARDWARE_CATEGORY_DESCRIPTIONS = new Map([
  [
    "gp",
    "Virtual machines with balanced resources suitable for a variety of workloads. Useful for website hosting, development/testing, and general application hosting.",
  ],
  [
    "mem-opt",
    "Virtual machines focused on providing larger amounts of memory. Useful for databases, in-memory caches, and other memory bound workloads.",
  ],
  [
    "cpu-opt",
    "Virtual machines focused on providing more processing cores. Useful for CI/CD, video processing, and other cpu bound workloads.",
  ],
]);

export const DEFAULT_SUBSCRIPTION = {
  cycle: "monthly",
  discount: 0,
  months: 1,
};
