export function removeDecimalIfWhole(price) {
  const priceNumber = Number(price);
  // Check if the price is a whole number (no decimal part)
  if (priceNumber === Math.floor(price)) {
    // If it's a whole number, convert it to an integer
    return Number(priceNumber.toFixed(0)).toLocaleString();
  } else {
    // If it's not a whole number, return it as is
    return Number(priceNumber)
      .toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .toString();
  }
}
