import { useMemo } from "react";
import { useAppState } from "../state"

export function useSubscriptionPricing() {
  const [{ configuration: configState, summary: summaryState }] = useAppState();
  const { serverQty, subscriptionCycle: { discount, months }} = configState;

  // Create cost sum from summary
  const configSum = useMemo(() => {
    // Exclude hardwareDetails from calculation
    const { hardwareDetails, ...rest } = summaryState;
    let sum = 0;

    Object.keys(rest).forEach((key) => {
      sum += Number(summaryState[key].cost);
    });

    return sum;
  }, [summaryState]);

  // Full cost
  const fullCost = Number(configSum) * months * serverQty;
  // Full cost with discount
  const discountedCost = fullCost - fullCost * parseFloat(discount) * 0.01;
  // Single unit cost with discount
  const singleCost = Number(configSum) - Number(configSum) * parseFloat(discount) * 0.01;

  return {
    fullCost,
    singleCost,
    discountedCost,
    discount,
  }
}
