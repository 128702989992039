import { find } from "lodash";
import React, { useEffect, useMemo, useState } from "react";

import { useProductDetails } from "../../../hooks/useProductDetails";
import { useAppState } from "../../../state";
import configActions from "../../../state/configuration/actions";
import summaryActions from "../../../state/summary/actions";
import { configurationHelper } from "../../../utils/configurationHelper";

import { removeDecimalIfWhole } from "../../../utils/removeDecimalIfWhole";
import { CardButtonBase } from "../common/card/CardButtonBase";
import { Chip } from "../common/chip";
import { SkeletonCard } from "../common/skeleton-card";
import { CYCLE_MAP } from "../constants";

export function SectionSubscription() {
  const [{ configuration: configState, summary: summaryState }, dispatch] =
    useAppState();
  const [basePrice, setBasePrice] = useState("");
  const productData = useProductDetails();
  const { status, data } = productData[configState.productCode];
  const { sortByKey } = configurationHelper(data);
  const showSkeleton =
    configState.isLoading || configState.isError === "api-fetch";
  const { hardwareCategory } = summaryState;

  useEffect(() => {
    if (hardwareCategory?.cost) {
      setBasePrice(hardwareCategory.cost);
    }
  }, [hardwareCategory.cost]);

  const cycles = useMemo(() => {
    if (status === "success" && data?.cycles) {
      return sortByKey(data.cycles, "months");
    }

    return [];
  });

  function getSubscriptionObject(cycle) {
    return {
      cycle: cycle.cycle,
      discount: Number(cycle.discount_percent),
      months: cycle.months,
    };
  }

  function handleSubscriptionCycle(cycle) {
    const subscriptionDiscount = getSubscriptionObject(cycle);

    dispatch(configActions.setSubscriptionCycle(subscriptionDiscount));
  }

  function getMonthlyCost(cycle) {
    if (Number(cycle.discount_percent) === 0) {
      return basePrice;
    }

    return (
      basePrice - basePrice * parseFloat(Number(cycle.discount_percent)) * 0.01
    );
  }

  if (!configState.hasSubscriptionCycle || !configState.isBareMetal) {
    return null;
  }

  return (
    <div className="mb-16 3xl:mb-28">
      <h3 className="text-xl font-normal mt-0 mb-6">
        Select Your Subscription
      </h3>

      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-2 sm:gap-4">
        {showSkeleton
          ? Array(3)
            .fill()
            .map((_, index) => (
              <SkeletonCard key={index} className="h-[178px] lg:h-[155px]" />
            ))
          : null}
        {!configState.isLoading &&
          configState.isError !== "api-fetch" &&
          cycles &&
          cycles.map((cycle) => {
            const monthlyCost = removeDecimalIfWhole(
              getMonthlyCost(cycle, basePrice),
            );

            return (
              <CardButtonBase
                key={cycle.cycle}
                className="lg:!p-4 2xl:!p-4"
                isSelected={cycle.cycle === configState.subscriptionCycle.cycle}
                onClick={() => handleSubscriptionCycle(cycle)}
              >
                <div className="flex flex-col w-full h-full">
                  <div className="flex flex-wrap items-start justify-between">
                    <h4 className="text-lg font-normal">
                      {CYCLE_MAP.get(cycle.cycle)}
                    </h4>
                    <Chip className="whitespace-nowrap">{`$${monthlyCost} Monthly`}</Chip>
                    {Number(cycle.discount_percent) > 0 && (
                      <div className="flex gap-1 text-sm mt-2 sm:mt-4 basis-full flex-wrap">
                        <span className="text-lw-text-disabled">
                          Billed upfront.
                        </span>
                        <span className="font-bold text-lw-ui-green">
                          {`Save ${Number(cycle.discount_percent)}%`}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </CardButtonBase>
            );
          })}
      </div>
    </div>
  );
}
