export default {
	cart: {
		redirectURL: 'https://stg-marketing.liquidweb.com/cart/checkout',
		cookieDomain: 'liquidweb.com',
	},
  chat: {
		baseCoreURL: 'https://liquidweb--developer.sandbox.my.salesforce.co',
		communityEndpointURL: 'https://liquidweb--developer.sandbox.my.site.com/public',
		gslbBaseURL: 'https://liquidweb--developer.sandbox.lightning.force.com',
		orgId: '00D0r0000000uuz',
		eswConfigDevName: 'Martech_Marketing_Site',
    eswScriptURL: 'https://liquidweb--developer.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
		settings: {
			baseLiveAgentContentURL: 'https://c.la2s-core1.sfdc-lywfpd.salesforceliveagent.com/content',
      baseLiveAgentURL: 'https://d.la2s-core1.sfdc-lywfpd.salesforceliveagent.com/chat',
			buttonId: '5730c0000004aJB',
			deploymentId: '5720c0000004aCZ',
			eswLiveAgentDevName: '',
			isOfflineSupportEnabled: false,
			chatbotAvatarImgURL: 'https://media.liquidweb.com/salesforce/img/liquid-web-logo.jpg',
		},
		storageDomain: 'localhost',
  }
}