/**
 * Remove objects from array based on the value of key
 * @param arr Source array
 * @param key Property to compare
 * @returns Array
 */
export default function removeDuplicateObjectsFromArray(arr, key) {
  if (!Array.isArray(arr) || !key) {
    return [];
  }
  return arr.filter(
    (obj, index, self) => index === self.findIndex((t) => t[key] === obj[key]),
  );
}
