import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { PickerFooter } from "./PickerFooter";

export function Picker({ className, children, footer }) {
  if (!children) {
    return null;
  }

  const pickerClasses = classnames(
    "p-4",
    "flex-col",
    "w-full",
    "border",
    "border-lw-ui-border",
    "rounded-lg",
    "focus-within:bg-white",
    "focus-within:border-lw-ui-green",
    className,
  );

  return (
    <div className={pickerClasses}>
      <div className="flex">{children}</div>
      <PickerFooter>{footer}</PickerFooter>
    </div>
  );
}

Picker.propTypes = {
  className: PropTypes.string,
  footer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
