import { forwardRef } from "react";

import { useAppState } from "../../../../state";
import { useSubscriptionPricing } from "../../../../hooks/useSubscriptionPricing";
import { removeDecimalIfWhole } from "../../../../utils/removeDecimalIfWhole";
import { SkeletonCard } from "../../common/skeleton-card";

export const TermSection = forwardRef(function (props, ref) {
  const [{ configuration: configState }] = useAppState();
  const { discountedCost, discount } = useSubscriptionPricing();
  const { subscriptionCycle } = configState;
  const { onClick } = props;
  const discountText = Number(discount) > 0 ? `Save ${Number(discount)}%` : "";

  function getBillingTermText() {
    switch (subscriptionCycle.cycle) {
      case "monthly":
        return "Billed Monthly";
      case "yearly":
        return "Billed Yearly";
      case "biennially":
        return "Billed Every 2 Years";
    }
  }

  if (!configState.hasSubscriptionCycle || !configState.isBareMetal) {
    return null;
  }

  return (
    <>
      <table className="text-sm w-full" aria-label="Operating System Choices">
        <caption>
          <div className="flex justify-between w-full text-xs mb-2">
            <span className="uppercase text-lw-text-disabled tracking-wide">
              Term
            </span>
            <button
              aria-label="Edit your Term choice"
              className="text-xs underline text-lw-text-disabled hover:text-lw-text-primary transition-all"
              onClick={() => onClick(ref)}
            >
              Edit
            </button>
          </div>
        </caption>
        <thead className="sr-only">
          <tr>
            <th>Term</th>
            <th>Cost</th>
          </tr>
        </thead>
        <tbody>
          {configState.isLoading || configState.isError === "api-fetch" ? (
            <tr>
              <td>
                <SkeletonCard className="w-full h-3 my-[3px] !rounded-sm !bg-lw-ui-border" />
              </td>
            </tr>
          ) : (
            <>
              <tr className="align-top">
                <td>
                  <span>{getBillingTermText()}</span>
                  <span className="text-lw-text-disabled">{` ($${removeDecimalIfWhole(discountedCost)})`}</span>
                </td>
                <td className="text-right">
                  { }
                  <span className="font-bold text-lw-ui-green">
                    {discountText}
                  </span>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      <hr className="border-t border-lw-ui-border my-4 " />
    </>
  );
});
