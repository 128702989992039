export default {
	cart: {
		redirectURL: 'https://stg-marketing.liquidweb.com/cart/checkout',
		cookieDomain: 'liquidweb.com',
	},
  chat: {
		baseCoreURL: 'https://liquidweb.my.salesforce.com',
		communityEndpointURL: 'https://sf-assets.liquidweb.com/public',
		gslbBaseURL: 'https://service.force.com',
		orgId: '00D30000000pmDy',
		eswConfigDevName: 'Liquidweb_Next',
    eswScriptURL: 'https://liquidweb.my.salesforce.com/embeddedservice/5.0/esw.min.js',
		settings: {
			baseLiveAgentContentURL: 'https://c.la1-core1.sfdc-8tgtt5.salesforceliveagent.com/content',
      baseLiveAgentURL: 'https://d.la1-core1.sfdc-8tgtt5.salesforceliveagent.com/chat',
			buttonId: '5730c0000004aJB',
			deploymentId: '5720c0000004aCZ',
			eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04IVx0000000HorMAE_18e571c8627',
			isOfflineSupportEnabled: false,
			chatbotAvatarImgURL: 'https://www.liquidweb.com/wp-content/uploads/2024/10/lw-avatar.png',
		},
		storageDomain: 'liquidweb.com',
  }
}
