import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import NvidiaLogo from "jsx:../../../images/nvidia.svg";
import { removeDecimalIfWhole } from "../../../utils/removeDecimalIfWhole";
import { CardButtonBase } from "../common/card/CardButtonBase";
import { Chip } from "../common/chip";
import {
    BARE_METAL_MAP,
    METAL_MAP,
    SERVER_TYPE_BARE_METAL,
    SERVER_TYPE_CLOUD_METAL,
    SERVER_TYPE_CLOUD_VPS,
    SERVER_TYPE_GPU,
    VPS_MAP,
} from "../constants";
import { ConfigCallouts } from "./ConfigCallouts";

export function HardwareCard({
  cost,
  billingCycle,
  isSelected,
  isBareMetal = false,
  disabled,
  onClick,
  serverType = SERVER_TYPE_CLOUD_VPS,
  data,
}) {
  const diskType = data?.disk_type ? ` ${data.disk_type}` : "";
  const raidLevel = data?.raid_level ? ` RAID-${data.raid_level}` : "";
  const isMetalServerType =
    serverType === SERVER_TYPE_CLOUD_METAL || isBareMetal;
  const chipClasses = classnames({
    "bg-lw-disabled": disabled,
    border: disabled,
    "border-lw-ui-border-disabled": disabled,
    "grow-1": isMetalServerType,
    "shrink-0": isMetalServerType,
    "basis-auto": isMetalServerType,
  });
  const cardHeaderClasses = classnames(
    "flex",
    "w-full",
    "mb-2",
    "gap-2",
    { "items-center": !isMetalServerType },
    isMetalServerType && [
      "items-start",
      "justify-between",
      "lg:flex-col-reverse",
      "lg:items-start",
      "xl:flex-row",
    ],
  );

  function getLinkSpeed(value) {
    if (!value) {
      return "";
    }

    return removeDecimalIfWhole((Number(value) / 1000).toFixed(1));
  }

  function getHardwareMap() {
    switch (serverType) {
      case SERVER_TYPE_CLOUD_METAL:
        return METAL_MAP;
      case SERVER_TYPE_BARE_METAL:
      case SERVER_TYPE_GPU:
        return BARE_METAL_MAP;
      default:
        return VPS_MAP;
    }
  }

  function getHardwareList() {
    let list = [];
    const map = getHardwareMap();

    map.forEach((item, index) => {
      list.push(
        <React.Fragment key={index}>
          <dt>{item.label}</dt>
          <dd className="text-right">{`${data[index]}${item.suffix ? ` ${item.suffix}` : ""}`}</dd>
        </React.Fragment>,
      );
    });

    return list;
  }

  function getGPU(gpu_name) {
    if (!gpu_name || serverType !== SERVER_TYPE_GPU) {
      return null;
    }

    // Trim "Nvidia" from string because the brand is represented with their logo.
    const gpuName = gpu_name.replace("Nvidia", "").trim();

    return (
      <div className="flex mt-4">
        <Chip className="!rounded !p-[6px] flex gap-2 align-center items-center">
          <NvidiaLogo className="basis-[74px] shrink-0"/>
          <span className="text-[14px] font-medium">{gpuName}</span>
        </Chip>
      </div>
    );
  }

  return (
    <CardButtonBase
      className={classnames(
        isMetalServerType && ["lg:items-start", "xl:items-center"],
      )}
      disabled={disabled}
      isSelected={isSelected}
      onClick={onClick}
      uniqueId={data.id}
    >
      <div className="flex flex-col w-full">
        <div className={cardHeaderClasses}>
          {isMetalServerType ? (
            <span className="text-lg">
              {data?.cpu_model ? `${data.cpu_model}` : ""}
            </span>
          ) : null}
          <Chip className={chipClasses}>{`$${cost} ${billingCycle}`}</Chip>
        </div>
        {isMetalServerType ? (
          <ConfigCallouts
            diskType={diskType}
            raidLevel={raidLevel}
            linkSpeed={getLinkSpeed(data?.link_speed)}
            isBareMetal={isBareMetal}
          />
        ) : null}
        <dl className="grid grid-cols-2 text-lw-text-disabled text-sm">
          {getHardwareList()}
        </dl>
        {getGPU(data?.gpu_name)}
      </div>
    </CardButtonBase>
  );
}

HardwareCard.propTypes = {
  cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  billingCycle: PropTypes.string,
  isSelected: PropTypes.bool,
  isBareMetal: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  serverType: PropTypes.oneOf([
    SERVER_TYPE_CLOUD_VPS,
    SERVER_TYPE_CLOUD_METAL,
    SERVER_TYPE_BARE_METAL,
    SERVER_TYPE_GPU,
  ]),
  data: PropTypes.object.isRequired,
};
