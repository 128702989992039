import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { CardButtonBase } from "../common/card/CardButtonBase";
import { Chip } from "../common/chip";

export function ControlPanelCard({
  className,
  orientation = "vertical",
  cost,
  logo,
  isSelected,
  disabled,
  onClick,
  children,
}) {
  const cardBaseClasses = classnames("min-h-[66px]", className);
  const logoClasses = classnames("grow", {
    grayscale: disabled,
    "opacity-60": disabled,
    "xl:grow-0": orientation === "vertical",
  });
  const chipClasses = classnames({
    "bg-lw-disabled": disabled,
    border: disabled,
    "border-lw-ui-border-disabled": disabled,
  });

  const cardClasses = classnames(
    "flex",
    "flex-row-reverse",
    "items-center",
    "flex-wrap",
    "w-full",
    "h-full",
    "align-end",
    "justify-end",
    {
      "xl:items-start": orientation === "vertical",
      "xl:flex-col": orientation === "vertical",
    },
  );

  const chipWrapperClasses = classnames("flex", "flex-col", "gap-2", {
    "xl:items-start": orientation === "vertical",
    "xl:w-full": orientation === "vertical",
    "xl:mb-10": orientation === "vertical",
    "xl:flex-row": orientation === "vertical",
    "xl:grow": orientation === "vertical",
  });

  return (
    <CardButtonBase
      className={cardBaseClasses}
      isSelected={isSelected}
      disabled={disabled}
      onClick={onClick}
    >
      <div className={cardClasses}>
        <div className={chipWrapperClasses}>
          {cost ? <Chip className={chipClasses}>{cost}</Chip> : null}
        </div>
        {logo ? <div className={logoClasses}>{logo}</div> : null}
        {children ? children : null}
      </div>
    </CardButtonBase>
  );
}

ControlPanelCard.propTypes = {
  cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  logo: PropTypes.node,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
