import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { useAppState } from "../../../state";

import { NavigationItem } from "./NavigationItem";

const logoImage = new URL(
  "../../../images/Liquid-Web-Logotype-Black-4-M.png?height=32",
  import.meta.url,
);

const ArrowDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M4.94 5.72656L8 8.7799L11.06 5.72656L12 6.66656L8 10.6666L4 6.66656L4.94 5.72656Z"
      fill="white"
    />
  </svg>
);

export function Navigation({ activeSection, sections, onClick }) {
  const [{ configuration: state }] = useAppState();

  return (
    <div className="fixed z-20 top-0 right-0 left-0 bg-white">
      <div className="relative py-4 px-4 sm:px-12">
        <div className="mx-auto w-full flex justify-between items-center">
          <div>
            <a href="/">
              <img
                src={logoImage}
                alt="Liquid Web Logo"
                width={102}
                height={20}
              />
            </a>
          </div>
          <nav className="hidden xl:flex gap-1 text-sm">
            {Array.isArray(sections)
              ? sections.map((section) => (
                  <NavigationItem
                    key={section.id}
                    onClick={() => onClick(section.id)}
                    label={section.label}
                    isActive={activeSection && section.id === activeSection}
                  />
                ))
              : null}
          </nav>
          <div className="relative xl:hidden">
            <span className="absolute right-3 top-3 z-1 pointer-events-none">
              <ArrowDown />
            </span>
            <select
              className="rounded-full text-base text-white bg-lw-black py-2 pl-4 pr-9 [appearance:none]"
              onChange={(event) => onClick(event.target.value)}
              value={activeSection}
            >
              {Array.isArray(sections)
                ? sections.map((section) => (
                    <option key={section.id} value={section.id}>
                      {section.label}
                    </option>
                  ))
                : null}
            </select>
          </div>
        </div>
        {state.isError ? (
          <div
            className={classnames(
              "absolute",
              "py-2",
              "px-4",
              "text-lw-black",
              "top-[calc(100%+16px)]",
              "left-4",
              "right-4",
              "bg-lw-ui-warning-light",
              "max-w-[78rem]",
              "mx-auto",
              "rounded",
              "flex",
              "items-center",
            )}
          >
            {state?.errorMessage ? state.errorMessage : "An error has occurred"}
          </div>
        ) : null}
      </div>
    </div>
  );
}

Navigation.propTypes = {
  activeSection: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sections: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
};
