import React, { useMemo, useEffect } from "react";
import classnames from "classnames";
import find from "lodash/find";

import { useProductDetails } from "../../../hooks/useProductDetails";
import { configurationHelper } from "../../../utils/configurationHelper";
import { useAppState } from "../../../state";
import configActions from "../../../state/configuration/actions";
import summaryActions from "../../../state/summary/actions";

import { QuantityControl } from "../quantity-control";
import { Chip } from "../common/chip";
import { SkeletonCard } from "../common/skeleton-card";

export function SectionIpAddresses() {
  const [{ configuration: state }, dispatch] = useAppState();
  const productData = useProductDetails();
  const { status, data } = productData[state.productCode];
  const { getDataByRegion } = configurationHelper(data);
  const showSkeleton =
    state.isLoading ||
    state.isError === "api-fetch" ||
    state.isError === "management";

  const ipData = useMemo(() => {
    if (status === "success") {
      const { extra_ip } = getDataByRegion(state.serverLocation);

      if (!extra_ip || extra_ip?.max === 0) {
        return {};
      }

      return extra_ip;
    }
  }, [status, state.serverLocation, getDataByRegion]);

  useEffect(() => {
    if (status === "success" && Object.keys(ipData).length) {
      const ipCount = state.ipAddresses;
      const costPerMonth = getMonthlyCost(ipData.per_ip_price);
      const cost = ipCount === 1 ? 0 : (ipCount - 1) * costPerMonth;

      dispatch(summaryActions.setIpAddresses({ value: ipCount, cost }));
    }
  }, [status, ipData, state.ipAddresses]);

  function getMonthlyCost(priceArray = []) {
    const priceObject = find(priceArray, { unit: "month" });
    return priceObject?.amount ? Number(priceObject.amount) : 5;
  }

  function getIpAddressDisplay() {
    const unitCost = getMonthlyCost(ipData.per_ip_price);

    return (
      <>
        {state.ipAddresses >= 1 ? (
          <>
            <span className="text-3xl">{state.ipAddresses}</span>
            <Chip>
              {state.ipAddresses === 1 && "No Cost"}
              {state.ipAddresses > 1
                ? `+$${(state.ipAddresses - 1) * unitCost} Monthly`
                : null}
            </Chip>
          </>
        ) : (
          <span className="text-3xl">None</span>
        )}
      </>
    );
  }

  if (!state.isLoading && (!ipData || Object.keys(ipData).length === 0)) {
    return null;
  }

  return (
    <div>
      <h3 className="text-xl font-normal mt-0 mb-2">Public IP Addresses</h3>
      <p className="mb-6">
        All servers include 1 Public IP address. Additional IPs cost $5.00 a
        month.
      </p>

      {showSkeleton ? (
        <SkeletonCard className="h-[72px]" />
      ) : (
        <QuantityControl
          min={1}
          max={ipData?.max ? ipData.max : 12}
          value={state.ipAddresses}
          onChange={(count) => dispatch(configActions.setIPAddresses(count))}
        >
          {ipData ? getIpAddressDisplay() : null}
        </QuantityControl>
      )}

      <div className="flex mt-6">
        {showSkeleton ? (
          <SkeletonCard className="h-[24px] w-1/2" />
        ) : (
          <label className="flex items-center">
            <input
              type="checkbox"
              className={classnames(
                "form-checkbox",
                "w-6",
                "h-6",
                "border-2",
                "border-lw-ui-border-disabled",
                "checked:border-lw-ui-border-hover",
                "checked:text-lw-ui-border-hover",
                "rounded",
              )}
              checked={state.addIPv6}
              onChange={() => {
                dispatch(
                  summaryActions.setIPv6({
                    value: !state.addIPv6 ? "Yes" : "No",
                  }),
                );
                dispatch(configActions.setIPv6());
              }}
            />
            <span className="ml-4">
              Add IPv6 (/64 block: 2<sup>^</sup>64 IPs)
            </span>
          </label>
        )}
      </div>
    </div>
  );
}
