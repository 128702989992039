import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export function PickerItem({ onClick, isDefault, disabled, active, children }) {
  const itemClasses = classnames(
    "rounded",
    "border",
    "disabled:bg-lw-disabled",
    "disabled:border-lw-disabled",
    "disabled:text-lw-text-disabled",
    "h-10",
    "flex-1",
    "transition-all",
    {
      "border-transparent": !isDefault && !active,
      "border-lw-ui-border": isDefault,
      "border-lw-green": active,
      "hover:border-lw-ui-border-hover": !active,
      "hover:bg-white": !active,
      "z-10": !active,
    },
  );

  return (
    <button disabled={disabled} onClick={onClick} className={itemClasses}>
      {children}
    </button>
  );
}

PickerItem.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
  cost: PropTypes.string,
  billingCycle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
