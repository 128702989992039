import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export function Chip({ className = "", children }) {
  return (
    <span
      className={classnames(
        "rounded-full",
        "px-2",
        "py-[6px]",
        "bg-lw-disabled",
        "text-sm",
        "leading-none",
        className,
      )}
    >
      {children}
    </span>
  );
}

Chip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
