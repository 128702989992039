import { useQueries } from "@tanstack/react-query";
import axios from "axios";

import {
  BASIC_AUTH,
  CART_DETAILS_URL,
  CLOUD_PRODUCTS,
  PRODUCT_BARE_METAL,
  PRODUCT_GPU,
} from "../components/configuration/constants";

export function useProductDetails() {
  async function fetchProductDetailsByCode(productCode) {
    const payload = [PRODUCT_BARE_METAL, PRODUCT_GPU].includes(productCode)
      ? { package_code: productCode }
      : { product_code: productCode };
    const { data } = await axios.post(
      CART_DETAILS_URL,
      {
        params: {
          ...payload,
        },
      },
      {
        headers: {
          "X-FORCE-COMPLIANT-HTTP-STATUS-CODES": 1,
        },
        auth: BASIC_AUTH,
      },
    );

    return data;
  }

  const queries = useQueries({
    queries: CLOUD_PRODUCTS.map((productCode) => {
      return {
        queryKey: ["productDetails", productCode],
        queryFn: async () => await fetchProductDetailsByCode(productCode),
      };
    }),
  });

  return queries.reduce((acc, details, idx) => {
    const productCode = CLOUD_PRODUCTS[idx];
    acc[productCode] = details;

    return acc;
  }, {});
}
