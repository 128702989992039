import React from "react";
import PropTypes from "prop-types";

export function PickerFooter({ children }) {
  if (!children) {
    return null;
  }

  return (
    <div className="mt-4 pt-4 border-t border-lw-ui-border">{children}</div>
  );
}

PickerFooter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
