const actions = {
  SET_IS_LOADING: "configuration/SET_IS_LOADING",
  setIsLoading: (payload) => ({
    type: actions.SET_IS_LOADING,
    payload,
  }),

  SET_IS_ERROR: "configuration/SET_IS_ERROR",
  setIsError: (payload) => ({
    type: actions.SET_IS_ERROR,
    payload,
  }),

  SET_ERROR_MESSAGE: "configuration/SET_ERROR_MESSAGE",
  setErrorMessage: (payload) => ({
    type: actions.SET_ERROR_MESSAGE,
    payload,
  }),

  SET_IS_BARE_METAL: "configuration/SET_IS_BARE_METAL",
  setIsBareMetal: (payload) => ({
    type: actions.SET_IS_BARE_METAL,
    payload,
  }),

  SET_SERVER_TYPE: "configuration/SET_SERVER_TYPE",
  setServerType: (payload) => ({
    type: actions.SET_SERVER_TYPE,
    payload,
  }),

  SET_SERVER_LOCATION: "configuration/SET_SERVER_LOCATION",
  setServerLocation: (payload) => ({
    type: actions.SET_SERVER_LOCATION,
    payload,
  }),

  SET_SERVER_ZONE_ID: "configuration/SET_SERVER_ZONE_ID",
  setServerZoneId: (payload) => ({
    type: actions.SET_SERVER_ZONE_ID,
    payload,
  }),

  SET_SERVER_REGION_ID: "configuration/SET_SERVER_REGION_ID",
  setServerRegionId: (payload) => ({
    type: actions.SET_SERVER_REGION_ID,
    payload,
  }),

  SET_HARDWARE_TAB: "configuration/SET_HARDWARE_TAB",
  setHardwareTab: (payload) => ({
    type: actions.SET_HARDWARE_TAB,
    payload,
  }),

  SET_HARDWARE_OPTION: "configuration/SET_HARDWARE_OPTION",
  setHardwareOption: (payload) => ({
    type: actions.SET_HARDWARE_OPTION,
    payload,
  }),

  SET_OPERATING_SYSTEM_ID: "configuration/SET_OPERATING_SYSTEM_ID",
  setOperatingSystemId: (payload) => ({
    type: actions.SET_OPERATING_SYSTEM_ID,
    payload,
  }),

  SET_OPERATING_SYSTEM_TYPE: "configuration/SET_OPERATING_SYSTEM_TYPE",
  setOperatingSystemType: (payload) => ({
    type: actions.SET_OPERATING_SYSTEM_TYPE,
    payload,
  }),

  SET_OPERATING_SYSTEM_DISTRO: "configuration/SET_OPERATING_SYSTEM_DISTRO",
  setOperatingSystemDistro: (payload) => ({
    type: actions.SET_OPERATING_SYSTEM_DISTRO,
    payload,
  }),

  SET_OPERATING_SYSTEM_VERSION: "configuration/SET_OPERATING_SYSTEM_VERSION",
  setOperatingSystemVersion: (payload) => ({
    type: actions.SET_OPERATING_SYSTEM_VERSION,
    payload,
  }),

  SET_OPERATING_SYSTEM_VERSION_VALUE: "configuration/SET_OPERATING_SYSTEM_VERSION_VALUE",
  setOperatingSystemVersionValue: (payload) => ({
    type: actions.SET_OPERATING_SYSTEM_VERSION_VALUE,
    payload,
  }),

  SET_OPERATING_SYSTEM_TAB: "configuration/SET_OPERATING_SYSTEM_TAB",
  setOperatingSystemTab: (payload) => ({
    type: actions.SET_OPERATING_SYSTEM_TAB,
    payload,
  }),

  SET_OPERATING_SYSTEM_TITLE: "configuration/SET_OPERATING_SYSTEM_TITLE",
  setOperatingSystemTitle: (payload) => ({
    type: actions.SET_OPERATING_SYSTEM_TITLE,
    payload,
  }),

  SET_OPERATING_SYSTEM_SUB_TITLE: "configuration/SET_OPERATING_SYSTEM_SUB_TITLE",
  setOperatingSystemSubTitle: (payload) => ({
    type: actions.SET_OPERATING_SYSTEM_SUB_TITLE,
    payload,
  }),

  SET_CONTROL_PANEL: "configuration/SET_CONTROL_PANEL",
  setControlPanel: (payload) => ({
    type: actions.SET_CONTROL_PANEL,
    payload,
  }),

  SET_TEMPLATE_VALUE: "configuration/SET_TEMPLATE_VALUE",
  setTemplateValue: (payload) => ({
    type: actions.SET_TEMPLATE_VALUE,
    payload,
  }),

  SET_MANAGEMENT_LEVEL: "configuration/SET_MANAGEMENT_LEVEL",
  setManagementLevel: (payload) => ({
    type: actions.SET_MANAGEMENT_LEVEL,
    payload,
  }),

  SET_SUBSCRIPTION_CYCLE: "configuration/SET_SUBSCRIPTION_CYCLE",
  setSubscriptionCycle: (payload) => ({
    type: actions.SET_SUBSCRIPTION_CYCLE,
    payload,
  }),

  SET_HAS_SUBSCRIPTION_CYCLE: "configuration/SET_HAS_SUBSCRIPTION_CYCLE",
  setHasSubscriptionCycle: (payload) => ({
    type: actions.SET_HAS_SUBSCRIPTION_CYCLE,
    payload,
  }),

  SET_PRODUCT_CODE: "configuration/SET_PRODUCT_CODE",
  setProductCode: (payload) => ({
    type: actions.SET_PRODUCT_CODE,
    payload,
  }),

  SET_BACKUPS: "configuration/SET_BACKUPS",
  setBackups: () => ({
    type: actions.SET_BACKUPS,
  }),

  SET_BLOCK_STORAGE: "configuration/SET_BLOCK_STORAGE",
  setBlockStorage: (payload) => ({
    type: actions.SET_BLOCK_STORAGE,
    payload,
  }),

  SET_IP_ADDRESSES: "configuration/SET_IP_ADDRESSES",
  setIPAddresses: (payload) => ({
    type: actions.SET_IP_ADDRESSES,
    payload,
  }),

  SET_IPV6: "configuration/SET_IPV6",
  setIPv6: () => ({
    type: actions.SET_IPV6,
  }),

  SET_DDOS_OPTION: "configuration/SET_DDOS_OPTION",
  setDdosOption: (payload) => ({
    type: actions.SET_DDOS_OPTION,
    payload,
  }),

  SET_CPANEL_LICENSE_TIER: "configuration/SET_CPANEL_LICENSE_TIER",
  setCpanelLicenseTier: (payload) => ({
    type: actions.SET_CPANEL_LICENSE_TIER,
    payload,
  }),

  SET_SERVER_QTY: "configuration/SET_SERVER_QTY",
  setServerQty: (payload) => ({
    type: actions.SET_SERVER_QTY,
    payload,
  }),

  SET_CART_TOTAL: "configuration/SET_CART_TOTAL",
  setCartTotal: (payload) => ({
    type: actions.SET_CART_TOTAL,
    payload,
  }),

  SET_CONFIGURATION_DATA: "configuration/SET_CONFIGURATION_DATA",
  setConfigurationData: (payload) => ({
    type: actions.SET_CONFIGURATION_DATA,
    payload,
  }),

  SET_MANAGEMENT_LEVEL_DATA: "configuration/SET_MANAGEMENT_LEVEL_DATA",
  setManagementLevelData: (payload) => ({
    type: actions.SET_MANAGEMENT_LEVEL_DATA,
    payload,
  }),

  SET_AVAILABLE_CONTROL_PANELS: "configuration/SET_AVAILABLE_CONTROL_PANELS",
  setAvailableControlPanels: (payload) => ({
    type: actions.SET_AVAILABLE_CONTROL_PANELS,
    payload,
  }),

  SET_AVAILABLE_DISTROS: "configuration/SET_AVAILABLE_DISTROS",
  setAvailableDistros: (payload) => ({
    type: actions.SET_AVAILABLE_DISTROS,
    payload,
  }),

  SET_WINDOWS_LICENSE: "configuration/SET_WINDOWS_LICENSE",
  setWindowsLicense: (payload) => ({
    type: actions.SET_WINDOWS_LICENSE,
    payload,
  }),

  SET_WINDOWS_MSSQL: "configuration/SET_WINDOWS_MSSQL",
  setWindowsMsSql: (payload) => ({
    type: actions.SET_WINDOWS_MSSQL,
    payload,
  }),

  SET_WINDOWS_ANTI_VIRUS: "configuration/SET_WINDOWS_ANTI_VIRUS",
  setWindowsAntiVirus: (payload) => ({
    type: actions.SET_WINDOWS_ANTI_VIRUS,
    payload,
  }),

  SET_AVAILABLE_WINDOWS_LICENSE: "configuration/SET_AVAILABLE_WINDOWS_LICENSE",
  setAvailableWindowsLicense: (payload) => ({
    type: actions.SET_AVAILABLE_WINDOWS_LICENSE,
    payload,
  }),

  UNSET_WINDOWS_SETTINGS: "configuration/UNSET_WINDOWS_SETTINGS",
  unsetWindowsSettings: () => ({
    type: actions.UNSET_WINDOWS_SETTINGS,
  }),

  RESET_ADD_ONS: "configuration/RESET_ADD_ONS",
  resetAddOns: () => ({
    type: actions.RESET_ADD_ONS,
  }),

  SET_AVAILABLE_MSSQL: "configuration/SET_AVAILABLE_MSSQL",
  setAvailableMsSql: (payload) => ({
    type: actions.SET_AVAILABLE_MSSQL,
    payload,
  }),

  SET_AVAILABLE_ANTI_VIRUS: "configuration/SET_AVAILABLE_ANTI_VIRUS",
  setAvailableAntiVirus: (payload) => ({
    type: actions.SET_AVAILABLE_ANTI_VIRUS,
    payload,
  }),

  RESET_CONFIGURATION: "configuration/RESET_CONFIGURATION",
  resetConfiguration: () => ({
    type: actions.RESET_CONFIGURATION,
  }),

  TOGGLE_IMPLICIT_CONFIG_UPDATE_NOTICE:
    "configuration/TOGGLE_IMPLICIT_CONFIG_UPDATE_NOTICE",
  toggleImplicitConfigUpdateNotice: (payload) => ({
    type: actions.TOGGLE_IMPLICIT_CONFIG_UPDATE_NOTICE,
    payload,
  }),

  SET_PACKAGE_VERSION_ID: "configuration/SET_PACKAGE_VERSION_ID",
  setPackageVersionId: (payload) => ({
    type: actions.SET_PACKAGE_VERSION_ID,
    payload,
  }),
};

export default actions;
